<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <b-card>
      <div
        v-if="$route.params.userId"
        class="head-card"
      >
        <div class="head-card-top">
          <h4>{{ $t('menu.sites') }}</h4>
          <feather-icon
            v-b-tooltip.hover.top="$t('Close')"
            icon="XIcon"
            size="18"
            class="toggle-close cursor-pointer"
            @click="$router.go(-1)"
          />
        </div>
        <hr>
      </div>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <div
        v-else-if="rows.length"
        class="sites-table"
      >
        <div class="custom-search flex-wrap ">
          <div class="total">
            <strong>{{ $t('sites.sitetotal') }}: {{ totals }}</strong>
          </div>
          <div class="table-filters">
            <div class="d-flex align-items-center">
              <b-input-group
                class="input-group-merge pnx-search"
              >
                <b-input-group-prepend is-text>
                  <feather-icon
                    icon="SearchIcon"
                    class="cursor-pointer"
                    @click="searchTerm = $refs.searchInput.vModelValue"
                  />
                </b-input-group-prepend>
                <b-form-input
                  ref="searchInput"
                  :value="searchTerm"
                  :placeholder=" $t('form.search')"
                  type="text"
                  class="d-inline-block"
                  @keyup.enter="searchTerm = $event.target.value"
                />
                <b-input-group-append is-text>
                  <div class="search-input-clear">
                    <feather-icon
                      v-if="searchTerm!==''"
                      v-b-tooltip.hover.top="$t('form.reset')"
                      class="cursor-pointer"
                      size="18"
                      icon="XIcon"
                      @click="clearSearch"
                    />
                  </div>
                  <b-dropdown
                    ref="dropdownFilter"
                    :variant="dropdownFilterVariant"
                    toggle-class="text-decoration-none"
                    right
                    no-caret
                    offset="15"
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        size="18"
                        icon="SlidersIcon"
                      />
                    </template>
                    <b-dropdown-form
                      :style="{minWidth: '292px'}"
                    >
                      <h5>
                        {{ $t('campaigns.filters') }}
                      </h5>
                      <hr>
                      <div class="d-flex align-items-center mb-1">
                        <label
                          class="mr-1"
                          :style="{minWidth: '60px'}"
                        >{{ $t('campaigns.status') }}</label>
                        <b-form-select
                          :value="filterStatus"
                          :options="[{ value: '', text: this.$t('users.all') }, ...optionsStatuses]"
                          @change="(value) => filterStatus = value"
                        />
                      </div>
                      <div class="d-flex align-items-center  mb-1">
                        <label
                          class="mr-1"
                          :style="{minWidth: '60px'}"
                        >{{ $t('sites.category') }}</label>
                        <b-form-select
                          :value="getFilterValue('category')"
                          :options="[{ value: '', text: this.$t('users.all') }, ...categories]"
                          @change="(value) => filterFn('category', value)"
                        />
                      </div>
                      <div class="d-flex align-items-center  mb-1">
                        <label
                          class="mr-1"
                          :style="{minWidth: '60px'}"
                        >{{ $t('stats.country') }}</label>
                        <b-form-select
                          :value="getFilterValue('country')"
                          :options="[{ value: '', text: this.$t('users.all') }, ...countries]"
                          @change="(value) => filterFn('country', value)"
                        />
                      </div>
                      <b-button
                        v-if="(getFilterValue('country') !== '' || getFilterValue('category') !== '' || filterStatus !== '')"
                        block
                        variant="danger"
                        @click="clearFilter"
                      >
                        <span>{{ $t('form.reset') }}</span>
                      </b-button>
                      <b-button
                        block
                        variant="outline-primary"
                        @click="$refs.dropdownFilter.hide(true)"
                      >
                        <span>{{ $t('Close') }}</span>
                      </b-button>
                    </b-dropdown-form>
                  </b-dropdown>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div>
              <b-button
                v-b-tooltip.hover.top="$t('sites.siteadd')"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="primary"
                class="btn-icon"
                :to="{ name: 'dashboard-user-site-create'}"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                />
                <span class="d-none d-sm-inline">{{ $t('sites.siteadd') }}</span>
              </b-button>
            </div>
          </div>
        </div>
        <!-- table -->
        <vue-good-table
          ref="sitesTable"
          :columns="columns"
          :rows="filterRows"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
          :sort-options="{
            enabled: true,
            initialSortBy: {field: 'shows', type: 'desc'}
          }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          style-class="vgt-table striped"
        >

          <template slot="column-filter">
            <span style="display: none;" />
          </template>
          <!-- Slot: Table Column -->
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span
              v-if="props.column.label ==='Status'"
              class="text-nowrap"
            >
              {{ $t('campaigns.status') }}
            </span>
            <span
              v-else-if="props.column.label ==='previous_month'"
              class="text-nowrap"
            >
              {{ $t('reportsTableHeaders.sites.previous_month') }}
            </span>
            <span
              v-else-if="props.column.label ==='current_month'"
              class="text-nowrap"
            >
              {{ $t('reportsTableHeaders.sites.current_month') }}
            </span>
            <span
              v-else-if="props.column.label ==='Name'"
              class="text-nowrap"
            >
              {{ $t('sites.sitetitle') }}
            </span>
            <span
              v-else-if="props.column.label ==='Category'"
              class="text-nowrap"
            >
              {{ $t('sites.category') }}
            </span>
            <span
              v-else-if="props.column.label ==='Country'"
              class="text-nowrap"
            >
              {{ $t('stats.country') }}
            </span>
            <span
              v-else-if="props.column.label ==='Impressions'"
              class="text-nowrap"
            >
              {{ $t('stats.impressions') }}
            </span>
            <span
              v-else-if="props.column.label ==='Action'"
              class="text-nowrap"
            >
              {{ $t('Action') }}
            </span>
            <span v-else>
              {{ props.column.label }}
            </span>
          </template>

          <!-- Slot: Table Row -->
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Status -->
            <span
              v-if="props.column.field === 'status'"
              class="text-nowrap"
            >
              <b-form-checkbox
                :checked="props.row.status === 'active' ? true : false"
                name="check-button"
                switch
                size="lg"
                @change="changeStatus(props.row._id)"
              />
            </span>

            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'title'"
              class="text-nowrap"
            >
              <router-link :to="'/users/'+ props.row.user_id + '/sites/'+ props.row._id + '/'"><strong>{{ trimEllip(props.row.title, 40) }}</strong></router-link>
              <b-badge
                v-if="props.row.is_moderate"
                class="ml-25"
                :variant="statusVariant('Resigned')"
              >
                {{ $t('campaigns.moderation') }}
              </b-badge>
              <template v-if="authUserData.role.includes('admin') && props.row.tags">
                <b-badge
                  v-for="(tag, idx) in props.row.tags.split(',')"
                  :key="idx"
                  class="tag-badge ml-25"
                  :variant="statusVariant('Tags')"
                  @click="setTag(tag)"
                >
                  {{ tag.trim() }}
                </b-badge>
              </template>
              <br>
              <small>{{ props.row.domain }}</small>
            </span>

            <span
              v-if="props.column.label ==='previous_month'"
              class="text-nowrap"
            >
              <small>{{ currency(account.currency, Object.values(props.row.earnings[0])[0]) }}</small>
            </span>
            <span
              v-if="props.column.label ==='current_month'"
              class="text-nowrap"
            >
              <small>{{ currency(account.currency, Object.values(props.row.earnings[1])[0]) }}</small>
            </span>

            <!-- Column: Country -->
            <span
              v-if="props.column.field === 'shows'"
              class="text-nowrap"
            >
              <small>{{ props.row.shows }}</small>
            </span>

            <!-- Column: Category -->
            <span
              v-if="props.column.field === 'category'"
              class="text-nowrap"
            >
              <small>{{ toCapitalize(props.row.category) }}</small>
            </span>

            <!-- Column: Country -->
            <span
              v-if="props.column.field === 'country'"
              class="text-nowrap"
            >
              <small>{{ toCapitalize(props.row.country) }}</small>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span class="d-flex justify-content-center">
                <b-button
                  v-b-tooltip.hover.top="$t('Statistics')"
                  :to="'/users/'+ props.row.user_id + '/sites/'+ props.row._id"
                  variant="flat"
                  class="btn-action"
                >
                  <feather-icon
                    icon="BarChart2Icon"
                    size="16"
                  />
                </b-button>
                <b-button
                  v-b-tooltip.hover.top="$t('form.change')"
                  :to="'/users/'+ props.row.user_id + '/sites/'+ props.row._id + '/edit/'"
                  variant="flat"
                  class="btn-action"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                  />
                </b-button>
                <b-button
                  v-b-tooltip.hover.top="$t('page.features.widgets')"
                  :to="'/users/'+ props.row.user_id + '/sites/'+ props.row._id + '/widgets/'"
                  variant="flat"
                  class="btn-action"
                >
                  <feather-icon
                    icon="GridIcon"
                    size="16"
                  />
                </b-button>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  right
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    :to="'/users/'+ props.row.user_id + '/sites/'+ props.row._id + '/promotions/'"
                  >
                    <feather-icon
                      icon="TagIcon"
                      class="mr-50"
                    />
                    <span>{{ $t('Promotions') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    :to="'/users/'+ props.row.user_id + '/sites/'+ props.row._id + '/clicks/'"
                  >
                    <feather-icon
                      icon="MousePointerIcon"
                      class="mr-50"
                    />
                    <span>{{ $t('Clicks sources') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    :to="'/users/'+ props.row.user_id + '/sites/'+ props.row._id + '/toparticles/'"
                  >
                    <feather-icon
                      icon="ActivityIcon"
                      class="mr-50"
                    />
                    <span>{{ $t('TOP articles') }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            {{ setTotals(props.total) }}
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  {{ $t('message.pagelength') }}
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['5','10','20','40']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{ $t('message.pageText2') }} </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
      <div v-else>
        <div
          class="text-center"
        >
          <h5>{{ $t('sites.nosites') }}</h5>
          <p>{{ $t('sites.addsite') }}:</p>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            :to="{ path: `/users/${$route.params.userId}/sites/create`}"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
            {{ $t('sites.siteadd') }}
          </b-button>
        </div>
      </div>
    </b-card>
  </div>

</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import options from '@/libs/options'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import { getUserData } from '@/auth/utils'
import currencyFormatter from '@/utils/currency-formatter'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import {
  BCard,
  BSpinner,
  BPagination,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BDropdownForm,
  BButton,
  VBTooltip,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { trimEllip, toCapitalize } from '@/utils/helpers'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Breadcrumbs,
    UserToolbar,
    BSpinner,
    BCard,
    VueGoodTable,
    BPagination,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BDropdownForm,
    BButton,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      currency: currencyFormatter,
      authUserData: getUserData(),
      account: {},
      pageTitle: '',
      pageSubtitle: '',
      breadcrumbItems: [],
      loading: true,
      pageLength: 20,
      dir: false,
      toCapitalize,
      trimEllip,
      columns: [
        {
          label: 'Name',
          field: 'title',
        },
        {
          label: 'Status',
          field: 'status',
          width: '9%',
          type: 'boolen',
        },
        {
          label: 'previous_month',
          field: 'previous_month',
        },
        {
          label: 'current_month',
          field: 'current_month',
        },
        {
          label: 'Impressions',
          field: 'shows',
          type: 'number',
          width: '10%',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Domain',
          field: 'domain',
          hidden: true,
        },
        {
          label: 'tags',
          field: 'tags',
          hidden: true,
        },
        {
          label: '_id',
          field: '_id',
          hidden: true,
        },
        {
          label: 'Category',
          field: 'category',
          filterOptions: {
            enabled: true,
            filterValue: '',
          },
          width: '10%',
          thClass: 'd-none d-lg-table-cell',
          tdClass: 'd-none d-lg-table-cell',
        },
        {
          label: 'Country',
          field: 'country',
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true,
            filterValue: '',
          },
          width: '10%',
        },
        {
          label: 'Action',
          field: 'action',
          thClass: 'text-center',
          width: '5%',
          sortable: false,
        },
      ],
      rows: [],
      totals: 0,
      searchTerm: '',
      filterStatus: '',
      optionsStatuses: [
        { value: 'is_active', text: 'Active' },
        { value: 'is_moderate', text: 'Moderation' },
        { value: 'is_new', text: 'New' },
        { value: 'parser_issue', text: 'Parser' },
        { value: 'has_shows', text: 'Have impressions' },
      ],
      ...options,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        active       : 'light-success',
        inactive     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        Tags          : 'light-secondary',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    dropdownFilterVariant() {
      if (this.getFilterValue('country') !== '' || this.getFilterValue('category') !== '' || this.filterStatus !== '') {
        return 'link'
      }
      return 'link-secondary'
    },
    filterRows() {
      let filtred = []
      if (this.filterStatus !== '') {
        if (this.filterStatus === 'is_active') {
          filtred = this.rows.filter(r => r.status === 'active')
        } else if (this.filterStatus === 'is_moderate') {
          filtred = this.rows.filter(r => r.is_moderate)
        } else if (this.filterStatus === 'is_new') {
          filtred = this.rows.filter(r => (new Date() - new Date(r.created_at)) <= (7 * 24 * 60 * 60 * 1000))
        } else if (this.filterStatus === 'parser_issue') {
          filtred = this.rows.filter(r => r.articles === undefined)
        } else if (this.filterStatus === 'has_shows') {
          filtred = this.rows.filter(r => r.shows >= 50)
        }
        return filtred
      }
      return this.rows
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    if (this.$route.params.userId === undefined) {
      this.fethSites()
    } else {
      this.fethAccountSites(this.$route.params.userId)
    }
  },
  methods: {
    clearSearch() {
      this.searchTerm = ''
    },
    clearFilter() {
      this.filterStatus = ''
      this.filterFn('category', '')
      this.filterFn('country', '')
    },
    setTag(tag) {
      this.searchTerm = tag.trim()
    },
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || []
      this.pageTitle = this.account.name
      this.pageSubtitle = this.account.email
      this.setBreabcrumbs()
    },
    async fethSites() {
      this.rows = []
      const responseData = await useJwt.sites()
      this.rows = responseData.data.sites || []
      this.pageTitle = this.$t('Sites')
      this.loading = false
      this.setBreabcrumbs()
    },
    async fethAccountSites(userId) {
      this.rows = []
      const responseAccountData = await useJwt.accountSites(userId)
      this.rows = responseAccountData.data.sites || []
      this.loading = false
      this.getUserData()
    },
    checkEnter(event) {
      if (event.code !== 'Enter') {
        event.preventDefault()
      }
    },
    setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: 'users',
          to: '/users',
        },
        {
          text: this.pageTitle,
          to: `/users/${this.$route.params.userId}`,
        },
        {
          text: 'sites',
          to: `/users/${this.$route.params.userId}/sites`,
        },
      ]
    },
    async changeStatus(id) {
      const responseData = await useJwt.getSite(id)
      const site = responseData.data.site || []
      site.status = site.status === 'inactive' ? 'active' : 'inactive'
      if (site.status === 'active') {
        this.$swal({
          icon: 'success',
          title: 'Activation!',
          text: 'Successfully activated.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        useJwt.setSite(id, { site })
      } else {
        this.$swal({
          title: 'Confirmation',
          text: 'Will be inactive',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$swal({
              icon: 'success',
              title: 'Deactivation!',
              text: 'Successfully deactivated',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          } else if (result.dismiss === 'cancel') {
            site.status = 'active'
            this.$swal({
              title: 'Cancelled',
              text: 'Status remain active',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          useJwt.setSite(id, { site })
        })
      }
    },
    filterFn(field, value) {
      const found = this.columns.find(c => c.field === field)
      if (found && found.filterOptions) {
        Object.assign(found.filterOptions, { filterValue: value })
      }
    },
    getFilterValue(field) {
      const found = this.columns.find(c => c.field === field)
      if (found && found.filterOptions && 'filterValue' in found.filterOptions && found.filterOptions.filterValue !== '') {
        return found.filterOptions.filterValue
      }
      return ''
    },
    setTotals(count) {
      this.totals = count
    },
    deleteEntity(entity, id, title) {
      this.$swal({
        title: `${this.$t('swal.deletetitle')} ${title}?`,
        text: this.$t('swal.deletemessage'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('form.cancel'),
        confirmButtonText: this.$t('form.delete'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt.deleteEntity(entity, id).then(deleted => {
            if (deleted.status === 204) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('swal.successfullydeleted'),
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              if (this.$route.params.userId === undefined) {
                this.fethSites()
              } else {
                this.fethAccountSites(this.$route.params.userId)
              }
            }
          })
        }
      })
    },
  },
}
</script>
